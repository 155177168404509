import React from "react";
import { Box, Typography } from "@mui/material"; // Importa Typography
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom"; // Importa useNavigate
import "react-multi-carousel/lib/styles.css"; // Asegúrate de importar los estilos de Carousel

function PromotionsCarousel() {
  const navigate = useNavigate(); // Usa el hook aquí

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1536 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 1536, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // Arreglo con las URLs de las imágenes
  const imageUrls = [
    "https://scontent.fcor2-1.fna.fbcdn.net/v/t1.6435-9/52469167_2474984749196038_2796830931619414016_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=06a7ca&_nc_ohc=bzUi2fkY6m0Q7kNvgGAs9Cf&_nc_zt=23&_nc_ht=scontent.fcor2-1.fna&_nc_gid=AAP4l-SmZeOW11WQ9-Yn57O&oh=00_AYB7MMFiCaYp8G_K0k51pJBusUPO3whgIBKJzjbC5yANDQ&oe=673FFCEF",
    "https://sanrafaelturismo.gov.ar/wp-content/uploads/2022/03/Copia-de-Vinedos-en-la-Cuesta-scaled.jpg",
    "https://d2r9epyceweg5n.cloudfront.net/stores/001/625/807/rte/imagen%20brindis%20personas.jpg",
    "https://latitur.com/uploads/media/cactus_product_tours_by_locals/12026.kalinawaventuras@gmail.com/18638/thumb_18638_cactus_product_tours_by_locals_large.jpeg.webp",
    "https://www.coravin.com/cdn/shop/articles/AdobeStock_137960178.jpg?v=1723514154",
    "https://larutanatural.gob.ar/panel/public/uploads/rutas-naturales/generated/w1800,h1253,fcrop,q80/76d41dc7-cfa9-42bf-bede-5fb122ef4e0c.jpg",
    "https://www.sanrafael.gov.ar/wp-content/uploads/2019/11/2.jpg",
  ];

  const handleClick = () => {
    navigate("/Catuel"); // Maneja el click aquí
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        showDots={false}
        arrows={true}
        renderButtonGroupOutside={false}
        ssr={true}
        swipeable={true}
        draggable={true}
        shouldResetAutoplay={false}
        focusOnSelect={false}
      >
        {imageUrls.map((url, index) => (
          <Box
            key={index} // Agrega una clave única para cada elemento
            sx={{
              width: "100vh",
              height: "400px",
              backgroundImage: `url(${url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
              "&:hover": {
                cursor: "pointer",
                filter: "brightness(0.8)", // Efecto de oscurecimiento
              },
            }}
            onClick={handleClick}
          >
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                bgcolor: "rgba(0, 0, 0, 0.6)", // Fondo oscuro
                color: "white", // Color del texto
                padding: 1, // Espaciado interno
              }}
            >
              <Typography color="white" sx={{ padding: "2vh" }} variant="h6">
                Excursiones Turisticas
              </Typography>{" "}
              {/* Cambia el texto según sea necesario */}
            </Box>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
}

export default PromotionsCarousel;
