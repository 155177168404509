import React from "react";
import { Box, Card, Typography, Fab } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

function FailurePage() {
  const navigate = useNavigate(); // Hook para navegar entre páginas
  return (
    <Box
      sx={{
        backgroundColor: "#f8d7da",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Fab
        size="large" // FAB pequeño
        color="primary"
        sx={{
          position: "fixed",
          top: 16, // Distancia desde la parte superior
          right: 30, // Distancia desde la izquierda
          zIndex: 1000, // Asegura que el FAB esté por encima de otros elementos
        }}
        onClick={() => navigate("/")} // Redirige a la página de inicio
      >
        <HomeIcon />
      </Fab>
      <Card
        sx={{
          padding: "2rem",
          borderRadius: "1rem",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          maxWidth: "00px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <ErrorIcon
          sx={{ fontSize: "6rem", color: "red", marginBottom: "1rem" }}
        />
        <Typography variant="h5" gutterBottom>
          ¡Error al procesar la reserva!
        </Typography>
        <Typography variant="body1">
          Hubo un problema al procesar tu reserva. Por favor, intenta nuevamente
          o contacta con soporte.
        </Typography>
      </Card>
    </Box>
  );
}

export default FailurePage;
