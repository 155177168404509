import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { TextField, Button, Grid, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createReservation } from "../api/reserves"; // Supongamos que tienes una función para enviar la reserva
import { differenceInDays } from "date-fns"; // Importamos para cálculo de fechas
import { initMercadoPago } from "@mercadopago/sdk-react";
import { useAuth } from "../context/AuthContext";
import { Divider, AppBar, Toolbar, Badge, Fab } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

import mppng from "../images/MP.svg";
const PayPage = () => {
  const { state } = useLocation();
  const { rooms, startDate, endDate, peopleCount, totalPrice } = state || {};
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [origin, setOrigin] = useState("");
  const [age, setAge] = useState("");
  const [reasonForVisit, setReasonForVisit] = useState("");
  const [dni, setDni] = useState("");
  const [numberphone, setNumberPhone] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");

  const [isVisible, setIsVisible] = useState(true); // Estado para saber si el componente es visible o no
  const componentRef = useRef(null); // Referencia al componente que vamos a observar

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleConfirmEmailChange = (e) => {
    setConfirmEmail(e.target.value);
  };
  const navigate = useNavigate();
  const { recharge } = useAuth();

  // Ejemplo: horario de check-in y check-out
  const checkInTime = "14:00 PM";
  const checkOutTime = "11:00 AM";

  // Cálculo de la cantidad de noches
  const nights = differenceInDays(new Date(endDate), new Date(startDate));

  // Inicializa MercadoPago SDK
  useEffect(() => {
    initMercadoPago("APP_USR-f95757a8-62f9-46aa-a35f-462ff2a82f40", {
      locale: "es-AR",
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // 'entry' contiene información sobre el estado de visibilidad del elemento
        console.log("Is visible:", entry.isIntersecting); // Debug: Ver si es visible
        setIsVisible(entry.isIntersecting); // Actualizar estado dependiendo de la visibilidad
      },
      {
        root: null, // Observa el viewport (ventana del navegador)
        threshold: 0.1, // Un 10% del componente debe ser visible para considerarse "intersecting"
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current); // Empieza a observar el componente
    }

    // Limpia el observer al desmontar el componente
    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const scrollToComponent = (componentId) => {
    const element = document.getElementById(componentId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log(`No se encontró un elemento con el ID: ${componentId}`);
    }
  };
  const formatCurrency = (value) => {
    return value.toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 0,
    });
  };
  if (!rooms) {
    return <div>No se han encontrado habitaciones seleccionadas.</div>;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (rooms.length === 0) {
      alert("Por favor, selecciona al menos una habitación.");
      return;
    }

    try {
      const reservationData = {
        rooms,
        startDate,
        endDate,
        fullName,
        dni,
        numberphone,
        email,
        origin,
        age,
        reasonForVisit,
        totalPrice,
      };
      console.log("reservationData antes de enviar:", reservationData);

      // Calcular el precio y obtener el ID de preferencia de MercadoPago
      const preference_id = await recharge({
        reservationData,
      });
      console.log(preference_id.data.id);
      // Redirigir al usuario a la página de pago
      const paymentUrl = `https://www.mercadopago.com.ar/checkout/v1/redirect?preference_id=${preference_id.data.id}`;
      window.location.href = paymentUrl;

      // Guardar la reserva en la base de datos

      await createReservation(reservationData);
      alert("Reserva realizada con éxito. Te contactaremos pronto.");
      navigate("/");
    } catch (error) {
      console.error("Error creando la reserva:", error);
    }
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <Box sx={{ backgroundColor: "#ebe4d2" }}>
      <Fab
        size="large" // FAB pequeño
        color="primary"
        sx={{
          position: "fixed",
          top: 16, // Distancia desde la parte superior
          right: 30, // Distancia desde la izquierda
          zIndex: 1000, // Asegura que el FAB esté por encima de otros elementos
        }}
        onClick={() => navigate("/")} // Redirige a la página de inicio
      >
        <HomeIcon />
      </Fab>
      <Box sx={{ backgroundColor: "#2F251C" }}>
        <Typography
          color="white"
          sx={{
            textAlign: "left",
            fontWeight: "bold",
            fontSize: {
              xs: "1.5rem", // Pantallas pequeñas
              sm: "1.5rem", // Pantallas medianas
              md: "2rem", // Pantallas grandes
              lg: "1.7rem", // Pantallas más grandes
            },
            marginTop: {
              xs: "1.5rem",
              sm: "1.5rem",
              md: "2rem",
              lg: "2.5rem",
            },
            marginLeft: {
              xs: "1.5rem",
              sm: "1.5rem",
              md: "2rem",
              lg: "2.5rem",
            },
          }}
        >
          Pagar y Reservar
        </Typography>
        <Typography
          sx={{
            color: "#fff",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: {
              xs: "1.2rem", // Pantallas pequeñas
              sm: "1.5rem",
              md: "1.3rem",
              lg: "1.3rem",
            },
            padding: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
            },
          }}
          variant="body1"
          gutterBottom
        >
          <strong>Reserva desde </strong>
          {new Date(startDate).toLocaleDateString("es-ES", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })}{" "}
          <strong>Hasta el </strong>{" "}
          {new Date(endDate).toLocaleDateString("es-ES", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })}{" "}
        </Typography>
      </Box>
      <Box
        sx={{ justifyContent: "center", display: "flex", marginTop: "2rem" }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            maxWidth: {
              xs: "90%", // Para pantallas pequeñas, más ancho
              sm: "80%",
              md: "70%",
              lg: "60%", // Para pantallas más grandes
            },
            display: "flex",
            flexDirection: {
              xs: "column", // Para pantallas pequeñas, más ancho
              sm: "column",
              md: "row",
            },
            padding: "3vh",
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ color: "#333" }}>
            {rooms.map((room) => (
              <Box sx={{ padding: "2vh" }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: {
                      xs: "1.2rem", // Pantallas pequeñas
                      sm: "1.5rem",
                      md: "1.3rem",
                      lg: "1.3rem",
                      xl: "1.3rem",
                    },
                  }}
                >{` ${capitalizeFirstLetter(room.name)}`}</Typography>
                <Typography>{` Habitación ${room.type} para ${room.occupants} Personas`}</Typography>
                <Typography>{` ${formatCurrency(
                  room.totalPrice / room.occupants
                )} Por Persona`}</Typography>
                <Typography>
                  <strong>Precio total:</strong>
                  {` ${formatCurrency(room.totalPrice)} `}
                </Typography>
              </Box>
            ))}
          </Typography>

          <Typography
            variant="body1"
            gutterBottom
            sx={{
              color: "#fff",
              display: {
                xs: "block", // Ocultar en pantallas pequeñas
                sm: "block", // Mostrar en pantallas medianas y grandes
              },
            }}
          >
            <strong>Raspa y gana we</strong>
          </Typography>

          <Box
            display="flex"
            flexDirection={"column"}
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Typography
              style={{ textAlign: "end" }}
              variant="body1"
              sx={{
                color: "black",
                fontSize: {
                  xs: "1.2rem", // Pantallas pequeñas
                  sm: "1.2rem",
                  md: "1.2rem",
                  lg: "1.2rem",
                  xl: "1.2rem",
                },
              }}
            >
              <strong>Precio total:</strong> ${totalPrice}
            </Typography>
            <Typography
              style={{ textAlign: "end" }}
              variant="body1"
              sx={{
                color: "black",
                fontSize: {
                  xs: "1.2rem", // Pantallas pequeñas
                  sm: "1.2rem",
                  md: "1.2rem",
                  lg: "1.2rem",
                  xl: "1.2rem",
                },
              }}
            >
              <strong>Valor de la Seña (50%):</strong> ${totalPrice / 2}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Button
          ref={componentRef}
          variant="contained"
          color="secondary"
          onClick={() => scrollToComponent("form")}
          sx={{
            padding: "2vh",
            width: {
              xs: "70%", // Pantallas pequeñas
              md: "50%", // Pantallas medianas
              lg: "53%", // Pantallas grandes
              xl: "40%", // Pantallas muy grandes
            },
            fontSize: {
              xs: "1rem", // Pantallas pequeñas
              md: "1rem", // Pantallas medianas
              lg: "1rem", // Pantallas grandes
              xl: "1rem", // Pantallas muy grandes
            },
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
          }}
        >
          Ingresa tus datos
        </Button>
      </Box>
      <Box
        id="form"
        ref={componentRef}
        sx={{
          backgroundColor: "#f0f0f0", // Fondo detrás del formulario
          border: "10px solid #2196f3", // Borde azul
          borderRadius: "8px", // Bordes redondeados
          padding: "24px", // Espacio interno
          marginTop: "24px",
          maxWidth: "800px", // Ancho máximo
          margin: "auto", // Centrar en la página
        }}
      >
        <Typography sx={{ textAlign: "center" }}>
          Ingresar Datos de la Reserva
        </Typography>
        <form onSubmit={handleSubmit} style={{ marginTop: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Nombre completo"
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
                fullWidth
                placeholder="Nombre y Apellido"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#1976D2", // Color del borde por defecto
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2", // Color del borde cuando pasas el mouse
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FF5722", // Color del borde cuando está enfocado
                    },
                  },
                  "& .MuiInputBase-root": {
                    "&.Mui-focused": {
                      backgroundColor: "#FFF3E0", // Color de fondo cuando está enfocado
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Correo electrónico"
                type="email"
                required
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="ejemplo@ejemplo.com"
                id="email"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#1976D2", // Color del borde por defecto
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2", // Color del borde cuando pasas el mouse
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FF5722", // Color del borde cuando está enfocado
                    },
                  },
                  "& .MuiInputBase-root": {
                    "&.Mui-focused": {
                      backgroundColor: "#FFF3E0", // Color de fondo cuando está enfocado
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Confirmar Correo electrónico"
                type="email"
                required
                fullWidth
                value={confirmEmail}
                onChange={(e) => setConfirmEmail(e.target.value)}
                placeholder="ejemplo@ejemplo.com"
                error={email !== confirmEmail && confirmEmail !== ""}
                helperText={
                  email !== confirmEmail && confirmEmail !== ""
                    ? "Los correos electrónicos no coinciden"
                    : ""
                }
                id="confirmEmail"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#1976D2", // Color del borde por defecto
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2", // Color del borde cuando pasas el mouse
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FF5722", // Color del borde cuando está enfocado
                    },
                  },
                  "& .MuiInputBase-root": {
                    "&.Mui-focused": {
                      backgroundColor: "#FFF3E0", // Color de fondo cuando está enfocado
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Documento"
                type="text"
                value={dni}
                onChange={(e) => setDni(e.target.value)}
                required
                fullWidth
                placeholder="1234567890"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#1976D2", // Color del borde por defecto
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2", // Color del borde cuando pasas el mouse
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FF5722", // Color del borde cuando está enfocado
                    },
                  },
                  "& .MuiInputBase-root": {
                    "&.Mui-focused": {
                      backgroundColor: "#FFF3E0", // Color de fondo cuando está enfocado
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Número de teléfono"
                type="phone"
                required
                fullWidth
                value={numberphone}
                onChange={(e) => setNumberPhone(e.target.value)}
                placeholder="+54 9 11 1234 5678"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#1976D2", // Color del borde por defecto
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2", // Color del borde cuando pasas el mouse
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FF5722", // Color del borde cuando está enfocado
                    },
                  },
                  "& .MuiInputBase-root": {
                    "&.Mui-focused": {
                      backgroundColor: "#FFF3E0", // Color de fondo cuando está enfocado
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="¿De dónde eres?"
                type="text"
                value={origin}
                onChange={(e) => setOrigin(e.target.value)}
                required
                fullWidth
                placeholder="Origen"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#1976D2", // Color del borde por defecto
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2", // Color del borde cuando pasas el mouse
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FF5722", // Color del borde cuando está enfocado
                    },
                  },
                  "& .MuiInputBase-root": {
                    "&.Mui-focused": {
                      backgroundColor: "#FFF3E0", // Color de fondo cuando está enfocado
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="¿Cuántos años tienes?"
                type="number"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                required
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#1976D2", // Color del borde por defecto
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2", // Color del borde cuando pasas el mouse
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FF5722", // Color del borde cuando está enfocado
                    },
                  },
                  "& .MuiInputBase-root": {
                    "&.Mui-focused": {
                      backgroundColor: "#FFF3E0", // Color de fondo cuando está enfocado
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="¿A que hora aproximada llegarias al hostel?"
                type="text"
                value={reasonForVisit}
                onChange={(e) => setReasonForVisit(e.target.value)}
                required
                fullWidth
                placeholder=" 14:01PM ! "
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#1976D2", // Color del borde por defecto
                    },
                    "&:hover fieldset": {
                      borderColor: "#1976D2", // Color del borde cuando pasas el mouse
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FF5722", // Color del borde cuando está enfocado
                    },
                  },
                  "& .MuiInputBase-root": {
                    "&.Mui-focused": {
                      backgroundColor: "#FFF3E0", // Color de fondo cuando está enfocado
                    },
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" align="center" marginBottom="2vh">
                La información completa de tu pedido será enviada a tu email
                después de realizar el pago. Por favor, verifica tu bandeja de
                entrada y carpeta de spam para asegurarte de recibir todos los
                detalles importantes.
              </Typography>

              <Button
                variant="contained"
                sx={{ backgroundColor: "#2196f3" }}
                type="submit"
                fullWidth
                disabled={email !== confirmEmail} // Deshabilitar el botón si los emails no son iguales
              >
                <Typography
                  sx={{ fontSize: "1.4rem", color: "#fff", fontWeight: "bold" }}
                >
                  Pagar
                </Typography>
                {/* Imagen PNG dentro del botón */}
                <img
                  src={mppng} // Cambia la ruta de la imagen
                  alt="Logo Mercado Pago"
                  style={{
                    width: "20%", // Ajusta el tamaño de la imagen
                    height: "20%", // Ajusta el tamaño de la imagen
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      {isVisible ? (
        ""
      ) : (
        <AppBar
          position="fixed"
          sx={{
            top: {
              xs: "auto", // Para pantallas extra pequeñas (arriba)
              md: "auto", // Para pantallas medianas en adelante (no arriba)
            },
            bottom: {
              xs: 0, // Para pantallas extra pequeñas (arriba)
              md: 0, // Para pantallas medianas en adelante (no arriba)
            },
            left: {
              xs: "center", // Para pantallas extra pequeñas (no en el lado derecho)
              md: "center", // Para pantallas medianas en adelante (lado derecho)
            },
            height: {
              xs: "auto", // No ocupa toda la altura en pantallas extra pequeñas
              md: "100px", // Ocupa toda la altura en pantallas medianas en adelante
            },
            width: {
              xs: "100%", // Ocupa todo el ancho en pantallas pequeñas
              md: "460px", // Ajusta el ancho de la barra en pantallas medianas en adelante
            },
            backgroundColor: {
              xs: "#2F251C", // Ocupa todo el ancho en pantallas pequeñas
            },
            backdropFilter: {
              xs: "none", // Sin desenfoque para pantallas medianas en adelante
              mb: "blur(10px)", // Aplica desenfoque para pantallas pequeñas
              lg: "blur(10px)", // Aplica desenfoque para pantallas pequeñas
              xl: "blur(2px)", // Aplica desenfoque para pantallas pequeñas
            },
            borderRadius: {
              xs: "none", // Sin desenfoque para pantallas medianas en adelante
              mb: "10px", // Aplica desenfoque para pantallas pequeñas
              lg: "10px", // Aplica desenfoque para pantallas pequeñas
              xl: "10px", // Aplica desenfoque para pantallas pequeñas
            },

            borderColor: {
              xs: "none", // Sin desenfoque para pantallas medianas en adelante
              mb: "black", // Aplica desenfoque para pantallas pequeñas
            },
            border: "2px solid #000",
            justifyContent: "center",
          }}
        >
          <Toolbar sx={{ textAlign: "center" }}>
            <Box
              sx={{
                padding: { xs: "3vh" },
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => scrollToComponent("form")}
                sx={{ padding: "1vh" }}
              >
                <Typography sx={{ fontSize: "1rem" }}>
                  Ingresa tus datos
                </Typography>
              </Button>
            </Box>
            <Box
              display="flex"
              flexDirection={"column"}
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Typography
                style={{ textAlign: "end" }}
                variant="body1"
                sx={{ color: "#fff" }}
              >
                <strong>Valor de la Seña (50%):</strong> ${totalPrice / 2}
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </Box>
  );
};

export default PayPage;
