import React from "react";
import { Box, Card, Typography, Fab } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";

function SuccessPage() {
  const navigate = useNavigate(); // Hook para navegar entre páginas

  return (
    <Box
      sx={{
        backgroundColor: "#ebe4d2",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Fab
        size="large" // FAB pequeño
        color="primary"
        sx={{
          position: "fixed",
          top: 16, // Distancia desde la parte superior
          right: 30, // Distancia desde la izquierda
          zIndex: 1000, // Asegura que el FAB esté por encima de otros elementos
        }}
        onClick={() => navigate("/")} // Redirige a la página de inicio
      >
        <HomeIcon />
      </Fab>
      <Card
        sx={{
          padding: "2rem",
          borderRadius: "1rem",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          maxWidth: "300px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <CheckCircleIcon
          sx={{ fontSize: "6rem", color: "green", marginBottom: "1rem" }}
        />
        <Typography variant="h5" gutterBottom>
          ¡Reserva completada con éxito!
        </Typography>
        <Typography variant="body1">
          Tu reserva ha sido procesada correctamente. Te enviaremos los detalles
          por correo. REVISA TU EMAIL
        </Typography>
      </Card>
    </Box>
  );
}

export default SuccessPage;
