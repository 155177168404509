import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import fondohome from "../images/fondohome.png";
import logopng from "../images/LOGOPRIVILEGIO.svg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InstagramIcon from "@mui/icons-material/Instagram";
import PromotionsCarousel from "../components/PromotionsCarousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useTawkTo from "../useTawkTo";
import "../styles/pulse.css"; // Asegúrate de que la ruta sea correcta

function HomePage() {
  const navigate = useNavigate();
  useTawkTo(); // Llama al hook `useTawkTo` aquí

  const handleClick = () => {
    navigate("/Catuel"); // Maneja el click aquí
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ebe4d2",
        backgroundImage: `url(${fondohome})`, // Correcta interpolación de la variable fondohome
        backgroundSize: "cover", // Asegura que la imagen de fondo cubra toda el área
        backgroundPosition: "bottom", // Centra la imagen de fondo
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      {/* Título */}
      <Typography
        color="white"
        sx={{
          fontSize: {
            xs: "1.3rem", // Tamaño de fuente para pantallas pequeñas
            sm: "1.5rem", // Tamaño de fuente para pantallas medianas
            md: "1.8rem", // Tamaño de fuente para pantallas grandes
            lg: "1.8rem", // Tamaño de fuente para pantallas más grandes
            xl: "2.5rem", // Tamaño de fuente para pantallas más grandes
          },

          textShadow: "5px 5px 4px rgba(0, 0, 0, 0.9)", // Sombra paralela,
          marginTop: "2vh",
        }}
        gutterBottom
      >
        Hospedate en el Corazón de Mendoza
      </Typography>

      {/* Imagen de fondo */}
      <Box
        component="img"
        src={logopng} // Aquí se usa la variable logopng
        alt="Fondo"
        sx={{
          maxWidth: {
            xs: "50vh", // Tamaño máximo para pantallas extra pequeñas
            sm: "300px", // Tamaño máximo para pantallas pequeñas
            md: "300px", // Tamaño máximo para pantallas medianas
            lg: "400px", // Tamaño máximo para pantallas grandes
            xl: "650px", // Tamaño máximo para pantallas extra grandes
          },
          paddingTop: {
            xs: "0", // Tamaño máximo para pantallas extra pequeñas
            sm: "0", // Tamaño máximo para pantallas pequeñas
            md: "0", // Tamaño máximo para pantallas medianas
            lg: "0", // Tamaño máximo para pantallas grandes
            xl: "3vh", // Tamaño máximo para pantallas extra grandes
          },
          paddingBottom: {
            xs: "0", // Tamaño máximo para pantallas extra pequeñas
            sm: "0", // Tamaño máximo para pantallas pequeñas
            md: "0", // Tamaño máximo para pantallas medianas
            lg: "0", // Tamaño máximo para pantallas grandes
            xl: "3vh", // Tamaño máximo para pantallas extra grandes
          },
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%", // Ajusta según el espacio necesario
        }}
      >
        <Button
          sx={{
            borderRadius: "3vh",
            marginTop: "2vh",
            marginBottom: "2vh",
            padding: "1.5vh",
            display: {
              xs: "none", // Tamaño de fuente para pantallas pequeñas
              sm: "flex", // Tamaño de fuente para pantallas medianas
              md: "flex", // Tamaño de fuente para pantallas grandes
            },
            fontSize: {
              xs: "1rem", // Tamaño de fuente para pantallas pequeñas
              sm: "1.3rem", // Tamaño de fuente para pantallas medianas
              md: "1.3rem", // Tamaño de fuente para pantallas grandes
              lg: "1.3rem", // Tamaño de fuente para pantallas más grandes
              xl: "1.5rem", // Tamaño de fuente para pantallas más grandes
            },
          }}
          onClick={() =>
            (window.location.href =
              "https://www.instagram.com/stories/highlights/17854169337245486/")
          }
          variant="contained"
          color="primary"
        >
          Instalaciones
          <InstagramIcon
            sx={{
              marginLeft: "1vh",
              fontSize: {
                xs: "1rem", // Tamaño de fuente para pantallas pequeñas
                sm: "1.3rem", // Tamaño de fuente para pantallas medianas
                md: "1.3rem", // Tamaño de fuente para pantallas grandes
                lg: "1.3rem", // Tamaño de fuente para pantallas más grandes
                xl: "2.5rem", // Tamaño de fuente para pantallas más grandes
              },
            }}
          />
        </Button>
        <Button
          sx={{
            borderRadius: "1vh",
            marginTop: "2vh",
            marginBottom: "2vh",
            padding: "1.5vh",
          }}
          onClick={() => navigate("/reserve")}
          variant="contained"
          color="secondary"
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: {
                xs: "1rem", // Tamaño de fuente para pantallas pequeñas
                sm: "1.3rem", // Tamaño de fuente para pantallas medianas
                md: "1.3rem", // Tamaño de fuente para pantallas grandes
                lg: "1.3rem", // Tamaño de fuente para pantallas más grandes
                xl: "1.8rem", // Tamaño de fuente para pantallas más grandes
              },
            }}
          >
            Reservar Hospedaje
          </Typography>
        </Button>

        <Button
          sx={{
            display: {
              xs: "none", // Tamaño de fuente para pantallas pequeñas
              sm: "none", // Tamaño de fuente para pantallas medianas
              md: "flex", // Tamaño de fuente para pantallas grandes
            },
            borderRadius: "3vh",
            marginTop: "2vh",
            marginBottom: "2vh",
            padding: "1.5vh",
            fontSize: {
              xs: "1rem", // Tamaño de fuente para pantallas pequeñas
              sm: "1.3rem", // Tamaño de fuente para pantallas medianas
              md: "1.3rem", // Tamaño de fuente para pantallas grandes
              lg: "1.3rem", // Tamaño de fuente para pantallas más grandes
              xl: "1.5rem", // Tamaño de fuente para pantallas más grandes
            },
          }}
          onClick={() =>
            (window.location.href =
              "https://www.google.com.ar/maps/place/Privilegio+Hostel/@-34.6153375,-68.3374091,19z/data=!4m9!3m8!1s0x9679094346063f0b:0xd07c4985dc5c7ba5!5m2!4m1!1i2!8m2!3d-34.6152876!4d-68.3377415!16s%2Fg%2F11tp2sh2c1?coh=164777&entry=tt&shorturl=1")
          }
          variant="contained"
          color="primary"
        >
          Ubicacion
          <LocationOnIcon
            sx={{
              marginLeft: "1vh",
              fontSize: {
                xs: "1rem", // Tamaño de fuente para pantallas pequeñas
                sm: "1.3rem", // Tamaño de fuente para pantallas medianas
                md: "1.3rem", // Tamaño de fuente para pantallas grandes
                lg: "1.3rem", // Tamaño de fuente para pantallas más grandes
                xl: "2.5rem", // Tamaño de fuente para pantallas más grandes
              },
            }}
          />
        </Button>
      </Box>
      <Button>
        <Typography
          onClick={handleClick}
          className="pulse-button" // Aplica la clase aquí
          color="white"
          sx={{
            textShadow: "5px 5px 4px rgba(0, 0, 0, 0.9)", // Sombra paralela,
            fontSize: {
              xs: "1.2rem", // Tamaño de fuente para pantallas pequeñas
              sm: "1.5rem", // Tamaño de fuente para pantallas medianas
              md: "1.5rem", // Tamaño de fuente para pantallas grandes
              lg: "1.8rem", // Tamaño de fuente para pantallas más grandes
              xl: "2.3rem", // Tamaño de fuente para pantallas más grandes
            },
          }}
        >
          👇 ¿Que hacer en Mendoza? 👇
        </Typography>
      </Button>
      {/* Botones */}
      <Button
        sx={{
          display: {
            xs: "flex", // Tamaño de fuente para pantallas pequeñas
            sm: "none", // Tamaño de fuente para pantallas medianas
            md: "none", // Tamaño de fuente para pantallas grandes
          },
          flexDirection: "column",
          borderRadius: "3vh",
          marginTop: "2vh",
          marginBottom: "2vh",
          padding: "1.5vh",
          fontSize: {
            xs: "1rem", // Tamaño de fuente para pantallas pequeñas
            sm: "1.3rem", // Tamaño de fuente para pantallas medianas
            md: "1.3rem", // Tamaño de fuente para pantallas grandes
            lg: "1.3rem", // Tamaño de fuente para pantallas más grandes
            xl: "1.5rem", // Tamaño de fuente para pantallas más grandes
          },
        }}
        onClick={() =>
          (window.location.href =
            "https://www.google.com.ar/maps/place/Privilegio+Hostel/@-34.6153375,-68.3374091,19z/data=!4m9!3m8!1s0x9679094346063f0b:0xd07c4985dc5c7ba5!5m2!4m1!1i2!8m2!3d-34.6152876!4d-68.3377415!16s%2Fg%2F11tp2sh2c1?coh=164777&entry=tt&shorturl=1")
        }
        variant="contained"
        color="primary"
      >
        Ubicacion
        <LocationOnIcon
          sx={{
            marginLeft: "1vh",
            fontSize: {
              xs: "1rem", // Tamaño de fuente para pantallas pequeñas
              sm: "1.3rem", // Tamaño de fuente para pantallas medianas
              md: "1.3rem", // Tamaño de fuente para pantallas grandes
              lg: "1.3rem", // Tamaño de fuente para pantallas más grandes
              xl: "2.5rem", // Tamaño de fuente para pantallas más grandes
            },
          }}
        />
      </Button>
      <Button
        sx={{
          borderRadius: "3vh",
          marginTop: "2vh",
          marginBottom: "2vh",
          padding: "1.5vh",
          flexDirection: "column",

          display: {
            xs: "flex", // Tamaño de fuente para pantallas pequeñas
            sm: "none", // Tamaño de fuente para pantallas medianas
            md: "none", // Tamaño de fuente para pantallas grandes
          },
          fontSize: {
            xs: "1rem", // Tamaño de fuente para pantallas pequeñas
            sm: "1.3rem", // Tamaño de fuente para pantallas medianas
            md: "1.3rem", // Tamaño de fuente para pantallas grandes
            lg: "1.3rem", // Tamaño de fuente para pantallas más grandes
            xl: "1.5rem", // Tamaño de fuente para pantallas más grandes
          },
        }}
        onClick={() =>
          (window.location.href =
            "https://www.instagram.com/stories/highlights/17854169337245486/")
        }
        variant="contained"
        color="primary"
      >
        Instalaciones
        <InstagramIcon
          sx={{
            marginLeft: "1vh",
            fontSize: {
              xs: "1rem", // Tamaño de fuente para pantallas pequeñas
              sm: "1.3rem", // Tamaño de fuente para pantallas medianas
              md: "1.3rem", // Tamaño de fuente para pantallas grandes
              lg: "1.3rem", // Tamaño de fuente para pantallas más grandes
              xl: "2.5rem", // Tamaño de fuente para pantallas más grandes
            },
          }}
        />
      </Button>

      <PromotionsCarousel />
    </Box>
  );
}

export default HomePage;
