import React, { useState } from "react";
import { Box, Typography, Grid, Button, Modal, Fab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import turismLaptop from "../images/turismLaptop2.png";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import HomeIcon from "@mui/icons-material/Home";

function Cdvino() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const navigate = useNavigate();
  const handleClick1 = () => {
    navigate("/losreyunos"); // Maneja el click aquí
  };
  const handleClick2 = () => {
    navigate("/Cdvinos"); // Maneja el click aquí
  };

  
  const handleWhatsAppClick = () => {
    const phoneNumber = "542604206967";
    const message =
      'Hola! Vengo de parte de Hostel Privilegio. Ya revisé los detalles de la excursión "Ciudad y Laberinto de Borges", incluyendo el precio ($32.000 por persona 🧍) y los días de salida disponibles (Martes o Jueves).\n\nMe gustaría reservar la excursión, ¿podrías ayudarme?';

    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.location.href = url; // Redirecciona a WhatsApp con el mensaje
  };
  const handleClose = () => {
    navigate("/reserve"); // Maneja el click aquí
  };
  return (
    <Box
      sx={{
        backgroundColor: "#ebe4d2",
        backgroundImage: `url(${"https://cloudfront-us-east-1.images.arcpublishing.com/artear/SPKOGJLD7NCPZOPND6VOU7BFFI.jpg"})`,
        backgroundSize: "cover",
        backgroundPosition: {
          xs: "right", // Para pantallas extra pequeñas
          sm: "right center", // Para pantallas pequeñas
          md: "right center", // Para pantallas medianas
          lg: "right bottom", // Para pantallas grandes
          xl: "right", // Para pantallas extra grandes
        },
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fab
        size="large" // FAB pequeño
        color="primary"
        sx={{
          position: "fixed",
          top: 16, // Distancia desde la parte superior
          right: 30, // Distancia desde la izquierda
          zIndex: 1000, // Asegura que el FAB esté por encima de otros elementos
        }}
        onClick={() => navigate("/")} // Redirige a la página de inicio
      >
        <HomeIcon />
      </Fab>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: 4,
            backgroundColor: "white",
            margin: "auto",
            mt: { xs: 5, md: 10 }, // ajusta la distancia superior según el tamaño de la pantalla
          }}
        >
          <Box
            sx={{
              backgroundColor: "#ebe4d2",
              justifyItems: "center",
              padding: { xs: "3vh", md: "5vh" }, // ajusta el padding para diferentes tamaños
              borderRadius: "5vh",
              width: "100%",
            }}
          >
            <Typography
              sx={{ mb: { xs: 2, md: 3 } }} // ajusta el margin-bottom para pantallas pequeñas
              color="black"
              variant={window.innerWidth < 600 ? "body1" : "h6"} // cambia el tamaño del texto en pantallas pequeñas
            >
              ¿Ya reservaste una habitación en Hostel Privilegio?
            </Typography>
            <Typography
              sx={{
                mb: { xs: 2, md: 3 },
                fontSize: { xs: "0.9rem", md: "1rem" },
              }}
              color="black"
              variant="body1"
            >
              Para poder asegurarte un lugar en la excursión, primero
              necesitamos saber qué día estarás alojado en nuestro hostel. Esto
              es importante para coordinar la disponibilidad y garantizar tu
              lugar en las actividades.
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Button
                variant="contained"
                sx={{
                  mb: { xs: 2, md: 0 },
                  mr: { md: 4 },
                  width: { xs: "100%", md: "auto" },
                }} // ajusta el botón en pantallas pequeñas
                onClick={handleWhatsAppClick}
                color="success"
              >
                Sí, ya reservé
              </Button>
              <Button
                variant="contained"
                sx={{ width: { xs: "100%", md: "auto" } }}
                onClick={handleClose}
              >
                No, aún no
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Grid
        container
        spacing={2}
        sx={{
          padding: {
            xs: "1rem", // Padding para pantallas pequeñas
            lg: "3rem", // Padding para pantallas grandes
          },
        }}
      >
        {/* Grid para el texto existente */}
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "2rem", // Para pantallas extra pequeñas (<600px)
                sm: "3rem", // Para pantallas pequeñas (600px a 960px)
                md: "3rem", // Para pantallas medianas (960px a 1280px)
                lg: "3.5rem", // Para pantallas grandes (1280px a 1920px)
                xl: "5.5rem", // Para pantallas extra grandes (>1920px)
              },
              color: {
                xs: "#fff", // Color para pantallas pequeñas
                md: "#fff", // Mantiene el mismo color en pantallas medianas
                lg: "#fff", // Cambia a blanco para pantallas grandes en adelante
              },
              textShadow: {
                xs: "2px 2px 2px rgba(0, 0, 0, 0.5)", // Sombras suaves en pantallas pequeñas
                md: "4px 4px 3px rgba(0, 0, 0, 0.7)", // Sombras intermedias en pantallas medianas
                lg: "5px 5px 4px rgba(0, 0, 0, 0.9)", // Sombras más pronunciadas en pantallas grandes
              },
              padding: {
                xs: "0rem", // Menor padding en pantallas pequeñas
                sm: "0rem", // Padding intermedio en pantallas medianas
                lg: "0rem", // Mayor padding en pantallas grandes
              },
              paddingBottom: {
                xs: "0.5rem", // Ajusta el espacio inferior dependiendo del tamaño
                md: "1rem",
                lg: "0rem",
              },
            }}
          >
            Circuito
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "2rem", // Para pantallas extra pequeñas (<600px)
                sm: "3.4rem", // Para pantallas pequeñas (600px a 960px)
                md: "3rem", // Para pantallas medianas (960px a 1280px)
                lg: "4.5rem", // Para pantallas grandes (1280px a 1920px)
                xl: "7.1rem", // Para pantallas extra grandes (>1920px)
              },
              color: {
                xs: "#fff", // Color para pantallas pequeñas
                md: "#fff", // Mantiene el mismo color en pantallas medianas
                lg: "#fff", // Cambia a blanco para pantallas grandes en adelante
              },
              textShadow: {
                xs: "2px 2px 2px rgba(0, 0, 0, 0.5)", // Sombras suaves en pantallas pequeñas
                md: "4px 4px 3px rgba(0, 0, 0, 0.7)", // Sombras intermedias en pantallas medianas
                lg: "5px 5px 4px rgba(0, 0, 0, 0.9)", // Sombras más pronunciadas en pantallas grandes
              },
              padding: {
                xs: "0rem", // Menor padding en pantallas pequeñas
                sm: "1rem", // Padding intermedio en pantallas medianas
                lg: "0rem", // Mayor padding en pantallas grandes
              },
            }}
          >
            Laberinto de Borges
          </Typography>
          <Box
            sx={{
              background: {
                xs: "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))", // Fondo sólido negro en pantallas medianas y mayores
                sm: "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))", // Fondo sólido negro en pantallas medianas y mayores
                mb: "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))", // Fondo sólido negro en pantallas medianas y mayores
                lg: "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))", // Transparente en pantallas pequeñas
                xl: "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))", // Transparente en pantallas pequeñas
              },
              padding: {
                xs: "1rem", // Padding para pantallas pequeñas
                sm: "1.5rem", // Más espacio en pantallas medianas
                md: "2rem", // Mayor padding en pantallas más grandes
              },
              borderRadius: "10px", // Bordes redondeados para una apariencia más moderna
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)", // Sombra para darle profundidad
              color: "white", // Color de texto blanco para contrastar con el fondo oscuro
              maxWidth: "800px", // Limitar el ancho para que el texto no se extienda demasiado
              margin: "0 auto", // Centrar el contenido horizontalmente
            }}
          >
            <Typography
              color="white"
              sx={{
                fontSize: {
                  xs: "0.9rem", // Texto pequeño en pantallas extra pequeñas (<600px)
                  sm: "1rem", // Ligero aumento en pantallas pequeñas (600px a 960px)
                  md: "1.1rem", // Para pantallas medianas (960px a 1280px)
                  lg: "1.2rem", // Aumenta un poco más para pantallas grandes (1280px a 1920px)
                  xl: "2rem", // Texto más grande en pantallas extra grandes (>1920px)
                },
                color: {
                  xs: "#fff", // Color para pantallas pequeñas
                  md: "#fff", // Mantiene el mismo color en pantallas medianas
                  lg: "#fff", // Cambia a blanco para pantallas grandes en adelante
                },
                lineHeight: {
                  xs: "1.4", // Altura de línea menor en pantallas pequeñas
                  sm: "1.6", // Altura de línea un poco más relajada
                  md: "1.8", // Mantener el texto aireado para pantallas medianas y grandes
                },
                padding: {
                  xs: "0.3rem", // Menos padding en pantallas pequeñas
                  md: "0.5rem", // Aumenta ligeramente en pantallas medianas
                  lg: "0rem", // Padding más amplio en pantallas grandes
                },
                fontFamily: "Roboto, sans-serif", // Fuentes claras y legibles
                textAlign: "justify", // Justificado para mayor legibilidad en textos largos
                margin: {
                  xs: "0.5rem 0", // Espaciado vertical pequeño en pantallas pequeñas
                  md: "1rem 0", // Mayor espaciado en pantallas medianas y grandes
                },
              }}
            >
              Catedral San Rafael Arcángel - Plaza San Martín - Parque Hipólito
              Irigoyen - Plaza Francia - Laberinto de Borges.
            </Typography>
            <Typography
              color="white"
              sx={{
                fontSize: {
                  xs: "1rem", // Texto pequeño en pantallas extra pequeñas (<600px)
                  sm: "1rem", // Ligero aumento en pantallas pequeñas (600px a 960px)
                  md: "1.1rem", // Para pantallas medianas (960px a 1280px)
                  lg: "1.2rem", // Aumenta un poco más para pantallas grandes (1280px a 1920px)
                  xl: "1.7rem", // Texto más grande en pantallas extra grandes (>1920px)
                },
                color: {
                  xs: "#fff", // Color para pantallas pequeñas
                  md: "#fff", // Mantiene el mismo color en pantallas medianas
                  lg: "#fff", // Cambia a blanco para pantallas grandes en adelante
                },
                lineHeight: {
                  xs: "1.4", // Altura de línea menor en pantallas pequeñas
                  sm: "1.6", // Altura de línea un poco más relajada
                  md: "1.8", // Mantener el texto aireado para pantallas medianas y grandes
                },
                padding: {
                  xs: "0.3rem", // Menos padding en pantallas pequeñas
                  md: "0.5rem", // Aumenta ligeramente en pantallas medianas
                  lg: "0rem", // Padding más amplio en pantallas grandes
                },
                fontFamily: "Roboto, sans-serif", // Fuentes claras y legibles
                textAlign: "justify", // Justificado para mayor legibilidad en textos largos
                margin: {
                  xs: "0.5rem 0", // Espaciado vertical pequeño en pantallas pequeñas
                  md: "1rem 0", // Mayor espaciado en pantallas medianas y grandes
                },
              }}
            >
              City tour en van en minibus por la hermosa ciudad de San Rafael,
              reviví su historia y crecimiento y terminá de soprenderte en el
              laberinto construído en homenaje a Jorge Luis Borges, respirá la
              tranquilidad de nuestro pueblo. INCLUYE: Traslado. Sale y vuelve
              al Hostel. Servicio de guía. Entrada. NO INCLUYE: Comidas.
              SALIDAS: Martes y Jueves.
            </Typography>
          </Box>
        </Grid>

        {/* Grid para el nuevo contenido (faltante) */}
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center", // Centra los elementos horizontalmente
            textAlign: "center",
            gap: 2, // Espacio entre los elementos
          }}
        >
          {/* Precio tachado */}

          <Box
            sx={{
              background:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
            }}
            display={"flex"}
          >
            <Typography
              color="white"
              sx={{
                fontSize: {
                  xs: "2rem",
                  md: "2rem",
                  lg: "2.5rem",
                  xl: "4rem",
                },
                marginRight: "1vh",
              }}
            >
              $32.000
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "1rem",
                  md: "2rem",
                  lg: "2.5rem",
                },
                color: "red",
                textDecoration: "line-through",
              }}
            >
              $40.000
            </Typography>
          </Box>

          <Button
            variant="contained"
            color="secondary"
            sx={{
              width: {
                xs: "80%",
                md: "50%",
                lg: "60%",
                xl: "70%",
              },

              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <Typography
              onClick={handleWhatsAppClick}
              sx={{
                padding: {
                  xs: "0.8vh",
                  md: "1.25rem",
                  lg: "1.5vh",
                  xl: "2vh",
                },
                fontSize: {
                  xs: "1.3rem",
                  md: "1.25rem",
                  lg: "1.5rem",
                  xl: "3rem",
                },
              }}
            >
              Reservar actividad
            </Typography>
          </Button>

          {/* Botones de "Caminos del vino" y "Volver a aventuras" de forma horizontal */}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ width: "80%" }}
          >
            <Grid item xs={6}>
              <Button
                onClick={handleClick1}
                variant="contained"
                color="success"
                fullWidth
                sx={{
                  fontSize: {
                    xs: "1rem",
                    md: "1rem",
                  },
                }}
              >
                <ArrowCircleLeftIcon></ArrowCircleLeftIcon>
                Los Reyunos
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={handleClick2}
                variant="contained"
                color="warning"
                fullWidth
                sx={{
                  fontSize: {
                    xs: "0.875rem",
                    md: "1rem",
                  },
                }}
              >
                Caminos del Vino
                <ArrowCircleRightIcon></ArrowCircleRightIcon>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Cdvino;
