import { useEffect } from "react";

const useTawkTo = () => {
  useEffect(() => {
    // Inicializa la API de Tawk.to
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();

    const script = document.createElement("script");
    script.src = "https://embed.tawk.to/672301e52480f5b4f5968e5a/1ibgbuq90"; // Asegúrate de que este sea el correcto
    script.async = true;
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");

    // Inserta el script en el documento
    const firstScript = document.getElementsByTagName("script")[0];
    firstScript.parentNode.insertBefore(script, firstScript);

    // Configura la API con tu clave
    Tawk_API.apiKey = "143bfb70983f28145fe205c0ffa084d68f22df9f";

    return () => {
      // Limpia el script al desmontar
      firstScript.parentNode.removeChild(script);
    };
  }, []);
};

export default useTawkTo;
